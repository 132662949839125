<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>서비스신청관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2 >신청자정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>신청회사명</label>
            <ul>
              <li>{{svcReqInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd ><label>신청번호</label>
            <ul>
              <li>{{ svcReqInfo.svcReqNo}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>신청자명</label>
            <ul>
              <li>{{svcReqInfo.reqUserNm}}</li>
            </ul>
          </dd>
          <dd ><label>신청자연락처</label>
            <ul>
              <li>{{ svcReqInfo.spTelNo}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>승인상태</label>
            <ul>
              <li>
                {{ svcReqInfo.apprvStatusNm }}
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2> 물류/상품정보</h2>
        <ul class="filter_list">
          <li>
            <dd><label>물류운영형태</label>
              <ul>
                <li>{{svcReqInfo.logiTypeNm}}</li>
              </ul>
            </dd>
          </li>
          <li>
            <dd ><label>주요운영상품</label>
              <ul>
                <li>{{ svcReqInfo.mainGoodsText}}</li>
              </ul>
            </dd>
            <dd><label>상품종류</label>
              <ul>
                <li>{{svcReqInfo.goodsCnt }}</li>
              </ul>
            </dd>
          </li>
          <li>
            <dd ><label>상품수량</label>
              <ul>
                <li>{{ svcReqInfo.goodsTypeCnt}}</li>
              </ul>
            </dd>
            <dd ><label>월 예상 출고건수</label>
              <ul>
                <li>{{ svcReqInfo.expectMonthOutCntNm}}</li>
              </ul>
            </dd>
          </li>
        </ul>
      </div>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>서비스 등급/기능 정보</h2>
      </div>
      <div>
        <table  border="0" cellpadding="0" cellspacing="0" class="basic_table mgt20">
          <colgroup >
            <col width="25%">
            <col width="25%">
            <col width="25%">
            <col width="25%">
          </colgroup>
          <tr>
            <th>서비스등급</th>
            <th>3D VIEW 적용여부</th>
            <th>미디어 저장 여부</th>
            <th>수기등록여부</th>
          </tr>
          <tbody>
          <tr >
            <td v-for="item in svcReqInfo.svcAddOnInfoResponseList"  class="txt_c">
              {{ item.svcGbn === constant.svcGbn.main ? item.svcGradeNm : item.svcYn }}
            </td>
            <td class="txt_c">{{ svcReqInfo.handflagYn }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w40">
      <ul>
        
        <li v-if="svcReqInfo.apprvStatus === constant.apprvStatus.wait" class="w20">
          <button class="large_btn "
                  @click="onClickDeleteSvcReq">삭제</button>
        </li>
        <li v-if="svcReqInfo.apprvStatus === constant.apprvStatus.wait" class="w20">
          <button class="large_btn orange"
                  @click="$router.push({name: 'ServiceRequestManageEdit' })">수정</button>
        </li>
        <li v-if="svcReqInfo.apprvStatus === constant.apprvStatus.wait" class="w20">
          <button class="large_btn green"
                  @click="approvalSvcReq">승인</button>
        </li>
        <li v-if="svcReqInfo.apprvStatus === constant.apprvStatus.wait" class="w20">
          <button class="large_btn dgray"
                  @click="rejectSvcReq">반려</button>
        </li>
        <li class="w20">
          <button class="large_btn"
                  @click="$router.push({ name: 'ServiceRequestManageList' })">목록</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'

export default {
  name   : 'ServiceRequestManageDetail',
  created () {
    const vm = this
    vm.getSvcReqMngDetail()
  },
  methods : {
    getSvcReqMngDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/svc/${vm.$route.params.svcReqNo}`)
      .then(function (response) {
          if (response.data.resultStatus.messageCode === '2000') {
            vm.svcReqInfo = response.data.resultData
          } else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        },
      )
    },
    approvalSvcReq : function() {
      const vm = this
      kendo.confirm('서비스신청을 승인하시겠습니까?').done(function (){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/svc/approval`,{svcReqNo : vm.$route.params.svcReqNo})
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                kendo.alert('승인되었습니다.').bind('close', function() {
                  vm.getSvcReqMngDetail()
                })
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      })
    },
    rejectSvcReq : function() {
      const vm = this
      kendo.confirm('서비스신청을 반려하시겠습니까?').done(function (){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/svc/reject`,{svcReqNo : vm.$route.params.svcReqNo})
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                kendo.alert('반려되었습니다.').bind('close', function() {
                  vm.getSvcReqMngDetail()
                })
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      })
    },
    onClickDeleteSvcReq : function() {
      const vm = this
      kendo.confirm('서비스신청정보를 삭제하시겠습니까?').done(function (){
        vm.deleteSvcReq()
      })
    },
    deleteSvcReq : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/svc/${vm.$route.params.svcReqNo}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'ServiceRequestManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
    data   : function () {
      return {
        constant : {
          svcGbn : { main : '01' },
          apprvStatus : { wait : '01', approval : '02', reject : '03', }
        },
        svcReqInfo  : {
          svcAddOnInfoResponseList :[]
        },
      }
    },
  }
</script>
